var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.hasExisingBudgetCodes
        ? [
            _c("v-alert", { attrs: { color: "info", dark: "" } }, [
              _vm._v(" Please remove budget codes first to update "),
              _c("b", [_vm._v("Budget Code Structure")]),
            ]),
          ]
        : _vm._e(),
      _c(
        "v-form",
        { attrs: { readonly: _vm.isFormReadOnly } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Budget Code Structure")]),
              _c(
                "v-card-text",
                { attrs: { md: "1" } },
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isClean,
                          expression: "!isClean",
                        },
                      ],
                      attrs: { dense: "", text: "", type: "info" },
                    },
                    [
                      _vm._v(" Changes were detected, Kindly click the "),
                      _c("strong", [_vm._v("SAVE")]),
                      _vm._v(" button above to confirm these changes. "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                _vm._l(_vm.structure, function (section, index) {
                  return _c(
                    "v-row",
                    { key: index, attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "center-text",
                          attrs: { cols: "12", md: "1" },
                        },
                        [_c("h3", [_vm._v(_vm._s(index + 1))])]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: section.name,
                              label: "Code Description",
                              "prepend-icon": !_vm.isFormReadOnly
                                ? "mdi-delete"
                                : undefined,
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.structure.$each[index].name
                              ),
                            },
                            on: {
                              "click:prepend": function ($event) {
                                return _vm.deleteRow(index)
                              },
                              blur: function ($event) {
                                return _vm.$v.structure.$each[
                                  index
                                ].name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.structure.$each[index].name.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.structure.$each[index].name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.structure.$each[index].name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "1" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: section.numChar,
                              label: "Num Char",
                              required: "",
                              type: "number",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.structure.$each[index].numChar
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.structure.$each[
                                  index
                                ].numChar.$touch()
                              },
                            },
                            model: {
                              value:
                                _vm.$v.structure.$each[index].numChar.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.structure.$each[index].numChar,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.structure.$each[index].numChar.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: section.locationDependent,
                              label: "Loc/Dept Code",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleLocationSelected($event, index)
                              },
                            },
                            model: {
                              value:
                                _vm.$v.structure.$each[index].locationDependent
                                  .$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.structure.$each[index]
                                    .locationDependent,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.structure.$each[index].locationDependent.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }